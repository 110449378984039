import { Button, Heading } from '@chakra-ui/react';
import { ConnectKitButton } from 'connectkit';

const ConnectWallet = () => {
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, isConnecting, show }) => {
        return (
          <Button
            onClick={!isConnecting ? show : () => {}}
            _hover={{ backgroundColor: '#fbcb14' }}
            _active={{
              backgroundColor: '#fbcb14',
              transform: 'translate3d(3px, 3px, 3px)',
              boxShadow: 'none',
            }}
            boxShadow="4px 4px #000"
            borderColor="black"
            borderWidth={2}
            borderRadius={22}
            backgroundColor="#fbcb14"
            color="black"
            py={{
              base: 5,
              md: 6,
            }}
            px={{
              base: 5,
              md: 8,
            }}
          >
            <Heading
              size={{ base: 'sm', md: 'lg' }}
              letterSpacing={1}
              fontWeight="900"
            >
              {isConnecting && 'CONNECTING...'}
              {!isConnecting && !isConnected && 'CONNECT WALLET'}
            </Heading>
          </Button>
        );
      }}
    </ConnectKitButton.Custom>
  );
};

export default ConnectWallet;

/* eslint-disable @next/next/no-img-element */
import { Flex, Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

import ImageAdoptAHeedong from '~/assets/images/adopt-a-heedong.png';
import ImageEntryMobile from '~/assets/images/entry-mobile.png';
import ImageEntry from '~/assets/images/entry.png';
import ConnectWallet from '~/lib/components/mint/ConnectWallet';
import config from '~/lib/config';

const Home = () => {
  const router = useRouter();
  const { isConnected } = useAccount();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (isConnected) router.push(config.mainRedirectRoute);
  }, [isConnected, router]);

  if (!hasMounted) return null;

  return (
    <Flex
      direction="column"
      justifyContent={{
        base: 'flex-start',
        md: 'center',
      }}
      backgroundImage={{
        base: `url(${ImageEntryMobile.src})`,
        md: `url(${ImageEntry.src})`,
      }}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition={{
        base: 'top center',
        md: 'center right',
      }}
      backgroundColor="#6354c3"
      h="100vh"
      w="full"
    >
      <NextSeo title="Home" />

      <Flex
        backgroundColor="transparent"
        direction="column"
        h={{
          base: '40vh',
          md: 'full',
        }}
        w={{
          base: '100vw',
          md: '40vw',
        }}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <img
          src={ImageAdoptAHeedong.src}
          alt="Adopt a HeeDong"
          style={{
            width: 'max(30vw, 250px)',
          }}
        />
        <Flex width="100%" justifyContent="center">
          {isConnected ? <Spinner /> : <ConnectWallet />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Home;
